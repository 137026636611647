import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { CloseIcon } from "./CloseIcon";
import "./index.css";
import { getSubdomainAndPath, urlForPath } from "./paths";
import { Menu } from "./widgets/MenuBar";
import { MENU_SEPARATOR, PopupMenuHandler } from "./widgets/PopupMenuHandler";
import { FloatingOverlay } from "@floating-ui/react";

let _requestLogin;

export function requestLogin() {
  if (allowedLogins && allowedLogins.length === 1) {
    window.open(allowedLogins[0].href, "skymass_login");
    return;
  }

  if (_requestLogin) _requestLogin(true);
}

function logout() {
  localStorage.removeItem("session");
  localStorage.removeItem("session.sig");
  localStorage.removeItem("user");
  location.assign("/auth/logout");
}

class _AuthState extends EventTarget {
  user: string | undefined = Cookies.get("user");

  loggedIn(user) {
    this.user = user;
    this.dispatchEvent(new Event("logged_in"));
    this.dispatchEvent(new Event("auth_change"));
  }

  loggedOut() {
    this.user = undefined;
    this.dispatchEvent(new Event("logged_out"));
    this.dispatchEvent(new Event("auth_change"));
  }
}

export const AuthState = new _AuthState();

const is_3rd_party = window.parent !== window;

function get_cookie(name: string) {
  return is_3rd_party ? localStorage.getItem(name) : Cookies.get(name);
}

export function useUser() {
  const [user, setUser] = useState(get_cookie("user"));
  useEffect(() => {
    // we are embedded in an iframe... we don't get storage or broadcast events
    if (is_3rd_party) {
      const handle = (e: MessageEvent) => {
        console.log(e);
        if (e.origin !== document.location.origin) return;
        if (!e.data.is_skymass) return;
        const { user, session, sig } = e.data;
        localStorage.setItem("user", user);
        localStorage.setItem("session", session);
        localStorage.setItem("session.sig", sig);
        AuthState.loggedIn(user);
        setUser(user);
      };
      window.addEventListener("message", handle);
      return () => {
        window.removeEventListener("message", handle);
      };
    } else if (window.BroadcastChannel) {
      const bc = new BroadcastChannel("skymass_auth");
      const handle = (e: MessageEvent) => {
        const { user } = e.data;
        AuthState.loggedIn(user);
        setUser(user);
      };
      bc.addEventListener("message", handle);
      return () => {
        bc.removeEventListener("message", handle);
        bc.close();
      };
    } else {
      const handle = (e: StorageEvent) => {
        if (e.key !== "login") return;
        AuthState.loggedIn(e.newValue);
        setUser(e.newValue || undefined);
      };
      window.addEventListener("storage", handle);
      return () => {
        window.removeEventListener("storage", handle);
      };
    }
  }, []);
  return user;
}

export function Header() {
  const user = useUser();
  const [showLogin, setShowLogin] = useState(false);
  _requestLogin = setShowLogin;
  useEffect(() => {
    const handle = (e) => setShowLogin(false);
    AuthState.addEventListener("auth_change", handle);
    return () => AuthState.removeEventListener("auth_change", handle);
  }, []);

  const logins = useLogins();

  const { is_skymass, subdomain } = getSubdomainAndPath(location.href);

  const docsItems =
    is_skymass || !subdomain
      ? [
          { label: "Home", onClick: () => location.assign("/") },
          {
            label: "Dev Settings",
            onClick: () => location.assign("/app/skymass/admin"),
          },
          {
            label: "Docs",
            onClick: () => location.assign("/app/skymass/docs"),
          },
          {
            label: "Components",
            onClick: () => location.assign("/app/skymass/components"),
          },
        ]
      : [];

  const dirItems = subdomain
    ? [
        {
          onClick: () => location.assign(urlForPath("")),
          label: "App Directory",
        },
        MENU_SEPARATOR,
      ]
    : [];

  const loginItems = logins
    ? logins.length > 1
      ? [
          {
            label: "Login…",
            onClick: () => setShowLogin(true),
          },
        ]
      : [
          {
            label: logins[0].label,
            onClick: () => location.assign(logins[0].href),
          },
        ]
    : [{ component: () => <div aria-busy={true} /> }];

  const userItems = [
    {
      onClick: logout,
      label: "Logout",
    },
  ];

  const userName = user
    ? [
        { label: user, onClick: () => undefined, disabled: true },
        MENU_SEPARATOR,
      ]
    : [];

  return (
    <>
      <nav className="topbar menubar">
        <span />
        <ul className="skymass_menu">
          <Menu
            {...(user
              ? { avatar: { placeholder: user.charAt(0) } }
              : { icon: "user" })}
            items={[
              ...userName,
              ...dirItems,
              ...(user ? userItems : loginItems),
              ...(docsItems.length ? [MENU_SEPARATOR] : []),
              ...docsItems,
            ]}
          />
        </ul>
      </nav>
      {showLogin ? (
        <LoginModal handleClose={() => setShowLogin(false)} />
      ) : null}
      <PopupMenuHandler />
    </>
  );
}

type LoginOption = {
  href: string;
  label: string;
  icon: string;
};

let allowedLogins: undefined | LoginOption[];
let allowedLoginsPromise: undefined | Promise<void>;

async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function useLogins() {
  const [logins, setLogins] = useState(allowedLogins);
  useEffect(() => {
    if (logins) return;

    // giving a delay to requesting the data to allow the initial render to
    // happen well (done reflexively, this has not been tested as necessary)
    allowedLoginsPromise ??= delay(100)
      .then(async () => {
        const res = await fetch(
          new URL("/auth/options.json", document.location.href)
        );

        if (res.status !== 200) throw res.status;

        const json = await res.json();
        if (json.status !== 200) throw json.status;

        allowedLogins = json.data.options;
      })
      .catch((error) => {
        console.error("/auth/options.json failure", error);
        allowedLogins = [];
      });

    allowedLoginsPromise.then(() => {
      setLogins(allowedLogins);
    });
  });

  return logins;
}

function LoginModal({ handleClose }) {
  const options = useLogins();

  const logins = options ? (
    options.length ? (
      options.map((option) => (
        <ImageLink
          key={option.href}
          url={option.href}
          text={option.label}
          imgURL={parcelImage(option.icon)}
        />
      ))
    ) : (
      <p>Something went wrong, please try again</p>
    )
  ) : (
    <p style={{ height: "100%" }} aria-busy />
  );

  useEffect(() => {
    const handle = (e) => e.key === "Escape" && handleClose();
    window.addEventListener("keydown", handle);
    return () => window.removeEventListener("keydown", handle);
  }, []);

  return (
    <dialog open className="login_modal">
      <article>
        <h5>Login…</h5>
        {logins}
        <CloseIcon onClick={handleClose} />
      </article>
    </dialog>
  );
}

function ImageLink({ url, text, imgURL }) {
  return (
    <a
      href={url}
      target="skymass_login"
      role="button"
      className="outline secondary"
      style={{
        width: "16em",
        display: "inline-grid",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${imgURL})`,
          backgroundPosition: "0 center",
          backgroundSize: "1.5em",
          paddingLeft: "2em",
          marginLeft: "2em",
          color: "var(--code-kbd-background-color)",
        }}
      >
        {text}
      </div>
    </a>
  );
}

function parcelImage(icon: string) {
  switch (icon) {
    case "github":
      return new URL("./images/github_logo.png", import.meta.url);
    case "google":
      return new URL("./images/google_logo.svg", import.meta.url);
    case "azure":
      return new URL("./images/azure_logo.svg", import.meta.url);
    case "auth0":
      return new URL("./images/auth0_logo.svg", import.meta.url);
  }
}
